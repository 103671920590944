import { Modal, Button } from 'react-bootstrap';
import '../../Componentes/CSS/tarjeta.css'; // Estilos personalizados

const ModalInstructivo = ({ show, handleClose2 }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose2}
            className="custom-modal"
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            size="sm" // El tamaño base del modal se ajusta según el contenido
            style={{ maxWidth: '100%' }} // Asegurarse de que el modal no sea más grande que el video
        >
            <Modal.Header closeButton className="bg-dark">
                <Modal.Title style={{ color: '#fff', textAlign: 'center', width: '100%' }}>
                    <div className="row justify-content-center align-items-center">
                        <h4>INSTRUCTIVO</h4>
                    </div>
                </Modal.Title>
            </Modal.Header>


            <Modal.Body className="datos">
                {/* Contenedor de video */}
                <div className="row justify-content-center" style={{ display: 'flex', justifyContent: 'center' }}>
                    <video
                          controls
                          controlsList="nodownload" // Deshabilita la opción de descarga en el reproductor
                        style={{
                            width: 'auto',    // No forzar el ancho, el video mantendrá su tamaño original
                            height: 'auto',   // Mantener la altura proporcional
                            maxWidth: '100%', // El video puede adaptarse al ancho del modal, pero no más grande que eso
                            maxHeight: '80vh', // No permitir que el video se haga más grande que el 80% de la altura de la ventana
                            objectFit: 'contain', // Mantener la relación de aspecto original del video sin distorsionar
                            margin: 'auto',   // Centrar el video
                        }}
                        onContextMenu={(e) => e.preventDefault()} // Deshabilitar clic derecho
                    >
                        <source
                            src={require('../../assets/registro-control.mp4')}
                            type="video/mp4"
                        />
                        Tu navegador no soporta la etiqueta de video.
                    </video>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalInstructivo;
