/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import axios from "axios";
import './Componentes/CSS/estilos.css'; 
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";  // Ensure that the import is correct
import IconoLogo from './Componentes/SVG/IconoLogo.svg'
import ModalEmergencia from './Security/modal/emergencia';
import ModalInstructivo from "./Security/modal/instructivo";
import API_ASISTENCIA from "./Componentes/config/apisAsistencia_Variables";



function App() {
  const [dni, setDni] = useState("");
  const [rol, setRol] = useState("");
  const [clave, setClave] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ip, setIp] = useState(null);
  const [fechaRol, setFechaRol] = useState(null);
  const [showModal, setShowModal] = useState(false);  // Estado para mostrar el modal
  const [showModal2, setShowModal2] = useState(false);  // Estado para mostrar el modal
  
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
        console.log(data.ip)
        Cookies.set("miip", data.ip);

      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchIp();
  }, []);
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('es-ES');
    setFechaRol(formattedDate);
    console.log(formattedDate)
  }, []);
  const encryptPassword = (password) => {
    // Use a secret key for encryption
    const secretKey = "your-secret-key";
    const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
    return encryptedPassword;
  };


  const obtenerDatos = async () => {
    let respuesta; // Declarar la variable fuera del bloque try
    try {
      const endpoint = API_ASISTENCIA.autenticacion(dni, clave);
      respuesta = await axios.get(endpoint); // Asignar el valor dentro del try
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      setErrorMessage("¡Los datos ingresados son incorrectos!");
      return; // Salir de la función si hay un error
    }

    // Usar `respuesta` aquí, ya que ahora está definida
    const rol = respuesta.data.SERE_chRol;
    const nombre = respuesta.data.SERE_chNOM;
    const apellidopat = respuesta.data.SERE_chAPEPAT;
    const apellidomat = respuesta.data.SERE_chAPEMAT;
    const dnilogin = respuesta.data.SERE_P_chDNI;
    const CONTRASENIA = respuesta.data.SERE_chCLAVE;
    const encryptedClave = encryptPassword(CONTRASENIA);

    const datos = {
      nombre: nombre,
      apellidopat: apellidopat,
      apellidomat: apellidomat,
      dnilogin: dnilogin,
      clave: encryptedClave
    };

    const datosString = JSON.stringify(datos);
    localStorage.setItem("datos", datosString);

    setRol(rol);
    Cookies.set("rol", rol);
    Cookies.set("nombre", nombre);
    Cookies.set("apellidopat", apellidopat);
    Cookies.set("apellidomat", apellidomat);
    Cookies.set("dnilogin", dnilogin);
    window.location.replace('/placa-registro');
  };


  const handleShow = () => setShowModal(true);   // Mostrar el modal
  const handleClose = () => setShowModal(false);  // Cerrar el modal

  const handleShow2 = () => setShowModal2(true);   // Mostrar el modal
  const handleClose2 = () => setShowModal2(false);  // Cerrar el modal

  const handleSubmit = (event) => {
    event.preventDefault();
    obtenerDatos();
  };

   return (
    <div className="global">
      <header></header>

      {errorMessage && <div className="error-message2">{errorMessage}</div>}

      <div className="card">
        <img
          src={require("./Componentes/Imagenes/logo_victoria.png")}
          className="loginlogo"
          style={{ margin: 'auto', width: '100%', height: '270px', padding: '15px 50px' }}
          alt="Logo"
        />
        <h2
          style={{
            width: '100%',
            fontSize: '13px',
            textAlign: 'center',
            padding: '10px',
            backgroundColor: '#02a012',
            color: 'white',
            fontWeight: 'bold'
          }}
        >
          <img src={IconoLogo} alt="Icono Logo" />
          REGISTRO DE CONTROL
        </h2>

        <form onSubmit={handleSubmit} className="form card">
          <div className="card_header d-flex justify-content-center align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="23" height="23">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="#02a012" d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z"></path>
            </svg>
            <h1 className="form_heading">Inicio de Sesión</h1>
          </div>

          <div className="field">
            <label className="label">DNI:</label>
            <input
              className="input"
              type="text"
              value={dni}
              onChange={(e) => setDni(e.target.value)}
              autoComplete="username"
            />
          </div>

          <div className="field">
            <label className="label">CONTRASEÑA:</label>
            <input
              className="input"
              type="password"
              value={clave}
              onChange={(e) => setClave(e.target.value)}
              autoComplete="current-password"
            />
          </div>

            <div className="row">
              {/* Button Ingresar */}
              <div className="col-6">
                <button
                  type="submit"
                  className="btn botonlogin btn-block w-100"
                  style={{ fontSize: '12px', width: '100%' }}
                >
                  <i class="fas fa-sign-in-alt"></i><br></br>
                  <strong> INGRESAR </strong>
                </button>
              </div>

              {/* Button Emergencia */}
              <div className="col-6" >
                      <button
                      type="button"
                      className="btn btn-danger btn-block"
                      onClick={handleShow}  // Muestra el modal al hacer clic
                      style={{ fontSize: '12px', width: '100%' }}
                    >
                      <i class="fas fa-phone-alt"></i>
                      <strong> EMERGENCIA</strong>
                    </button>
              </div>
            </div>
            <div className="row">
              {/* Button Ingresar */}
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-dark btn-sm btn-block mt-2"
                  onClick={handleShow2}  
                  style={{ fontSize: '12px', width: '100%' }}
                >
                  <i class="fas fa-play"></i><br></br>
                  <strong> INSTRUCTIVO </strong>
                </button>
              </div>
            </div>
        </form>
      </div>

      {/* Incluir el ModalEmergencia y pasarle las props */}
      <ModalEmergencia 
        showModal={showModal} 
        handleClose={handleClose} 
      />

      <ModalInstructivo
          show={showModal2} // Asegúrate de pasar la prop `show` y no `showModal2`
          handleClose2={handleClose2} 
      />

    </div>
  );
}

export default App;